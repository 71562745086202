import { graphql } from 'gatsby'
import * as React from 'react'

import Content from '../components/Content'
import Main from '../components/Main'
import Seo from '../components/seo'

const EtusivuPage = ({ data, location }) => {
  const { page } = data

  return (
    <Main>
      <Seo metadata={page.metadata} />
      <Content content={page.content} />
    </Main>
  )
}

export default EtusivuPage

export const query = graphql`
  query EtusivuQuery {
    page: sanityPage(id: { eq: "-4a87bc78-f988-5a94-9e4a-6e43baa19617" }) {
      ...contentFragment
    }
  }
`
